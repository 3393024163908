import React from 'react';
import {useParams} from 'react-router-dom';
import inventoryData from '../../data/inventoryData';
import ProductCarousel from '../../components/ProductCarousel';

const OtherServicesCatalogue = () => {
    const {productCategory} = useParams();
    
    const eventType= 'otherServices';

    const filteredCategoryData = inventoryData.filter((item)=> item.category === productCategory && item.eventType === eventType);
    const sortedCategoryData = filteredCategoryData.sort((a, b) => 
        a.title.localeCompare(b.title) // Change 'title' to any other property as needed
    );
    const groupedEvents = sortedCategoryData.reduce((acc, event) => {
        if (!acc[event.subCategory]) {
            acc[event.subCategory] = [];
        }
        acc[event.subCategory].push(event);

        return acc;
    }, {});

    return (

        <div> 
            {Object.entries(groupedEvents).map(([category, events]) => (
                <ProductCarousel key={category} category={category} events={events} />
            ))}
        </div>
    );
};

export default OtherServicesCatalogue;

