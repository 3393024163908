import React from 'react';
import heroBg from '../../assets/logo.png';
import styles from './WhoAreWe.module.css';
const WhoAreWe = () => {
    return (
        <div className={styles.heroContainer}>
            <div className={styles.textContainer}>
                <h1 className={styles.headerText}>Crafting Moments, Creating Memories</h1>
                <p className={styles.subText}>At PC&I, we believe in the power of creativity to transform special moments into unforgettable memories. We are your trusted destination for beautifully designed stationery items that add a touch of elegance and style to your wedding events, parties, corporate gatherings, or any occasion worth celebrating.</p>
            </div>
            <div className={styles.imageContainer}>
                <img src={heroBg} alt="Hero" className={styles.heroImage}  loading="lazy" />
            </div>
        </div>
    );
};

export default WhoAreWe;