import React, { useState, useEffect, Fragment } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProductCard from './ProductCard';
import styles from './ProductCarousel.module.css';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ProductCarousel = ({ category, events }) => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 768);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const CustomNextArrow = (props) => (
        <button {...props} className={styles['carousel-arrow-right']}>
            <FontAwesomeIcon icon={faChevronRight} />
        </button>
    );

    const CustomPrevArrow = (props) => (
        <button {...props} className={styles['carousel-arrow-left']}>
            <FontAwesomeIcon icon={faChevronLeft} />
        </button>
    );

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const alignLeft = events.length < 4 ? styles['align-left'] : '';

    return (
       
        <Fragment>
            <div className={styles['product-carousel-container']}>
                {isDesktop ? (
                    <>
                <p className={styles['card-header']}>{category}</p>
                    <Slider {...settings} className={alignLeft}>
                        {events.map((event) => (
                            <div key={event.id} className={styles['product-card-container']}>
                                <ProductCard product={event} />
                            </div>
                        ))}
                    </Slider></>
                ) : (
                    <div className={alignLeft}>
                        {events.map((event) => (
                            <div key={event.id} className={styles['product-card-container']}>
                                <ProductCard product={event} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </Fragment>

    );
};

export default ProductCarousel;
