import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import styles from './ProductCard.module.css';
import CardBtn from './CardBtn';
import { Helmet } from 'react-helmet';

const ProductCard = ({product}) => {

    return (
        <>
        {console.log(product)}
        <Helmet>
         <title>{product.eventType}- Personalized Cards and Invitations</title>
         <meta name="description" content="Explore our elegant and custom wedding invitations, designed to make your special day even more memorable." />
         </Helmet>
        <Fragment>
            {product.id.includes('placeholder')?
                (
                    <div className={styles['product-card-button-link']}>
                        <div className={styles['product-card']}>
                            <div className={styles['card-content']}>
                                <img src={product.img1} alt={product.title} className={styles['card-content']}  loading="lazy"/>
                                <h3 className={styles['product-card-button']}>
                                    {product.title.toUpperCase()}
                                </h3>
                                <CardBtn title='Coming Soon'/>
                            </div>
                        </div>
                    </div>
                ):
                <Link to={`${product.url}/${product.id}/${product.title}`} className={styles['product-card-button-link']}>
                
                <div className={styles['product-card']}>
                    <div className={styles['card-content']}>
                        <img src={product.img1} alt={product.title} className={styles['card-content']}  loading="lazy"/>
                            <h3 className={styles['product-card-button']}>
                                {product.title.toUpperCase()}
                            </h3>
                        {/* <CardBtn title='View'/> */}
                    </div>
                </div>
            </Link>
            }
        </Fragment>
        </>
    );
};

export default ProductCard;
