import React, { useState, useEffect, useRef } from 'react';
import {Link, NavLink} from 'react-router-dom';
import WhatsappBtn from './WhatsappBtn';
import './kevnavbar.css';
import  logo from '../assets/PCI Logo brand.png';

function KevNavigationBar() {
    const [isActive, setIsActive] = useState(false);
    const navRef = useRef(null);
    const toggleMenu = () => {
        setIsActive(!isActive);
    };

    const closeMenu = () => {
        setIsActive(false);
    };

    useEffect(() => {
        // Add a click event listener to the document
        document.addEventListener('click', handleOutsideClick);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, );
    const handleOutsideClick = (e) => {
        // Check if the click target is out of the navigation bar
        if (navRef.current && !navRef.current.contains(e.target)) {
            closeMenu();
        }
    };
    return (
        <nav className="navbar" ref={navRef}>
            <Link to='/' className="link">
            <div className="navbar-logo logo logo-regular">
                <div className="left">
                    <img src={logo} alt="Logo" />
                    <span className="companyName">Personalized Cards & Invitations</span>
                </div>
            </div>
                <div className="navbar-logo logo logo-shorten">
                <div className=" left">
                    <img src={logo} alt="Logo" />
                </div>
            </div>
            </Link>

            <ul className={`navbar-links ${isActive ? 'active' : ''}`}>
                <li className="navItem"><NavLink onClick={closeMenu} to="/wedding">Wedding</NavLink></li>
                <li className="navItem"><NavLink onClick={closeMenu} to="/corporate">Business</NavLink></li>
                <li className="navItem"><NavLink onClick={closeMenu} to="/reviews">Reviews</NavLink></li>
                <li className="navItem"><NavLink onClick={closeMenu} to="/forms">Forms</NavLink></li>

                <li>
                    <WhatsappBtn title='Whatsapp Us'/>
                </li>
            </ul>
            <div className="navbar-toggle" onClick={toggleMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
        </nav>
    );
}

export default KevNavigationBar;
