import React from 'react';
import styles from './ShipIslandwide.module.css';
import aboutUs from '../../assets/images/aboutUs3.png';


const ShipIslandwide = () => {
    return (
        <div className={styles.heroContainer}>
            <div className={styles.textContainer}>
                <h1 className={styles.headerText}>We Ship Islandwide</h1>
                <p className={styles.subText}>No matter where you are in our beautiful island, we've got you covered. PC&I offers islandwide shipping, so you can enjoy our creative stationery products wherever you are. We take care to ensure your order arrives promptly and in pristine condition.</p>
            </div>
            <div className={styles.imageContainer}>
                <img src={aboutUs} alt="Hero" className={styles.heroImage}  loading="lazy" />
            </div>
        </div>

    );
};

export default ShipIslandwide;