import React from 'react';
import styles from './Banner.module.css';
import bank from '../../assets/images/Bank_Transfer.png';
import ncb from '../../assets/images/ncb.png';
import scotia from '../../assets/images/scotia.png';
import Marquee from "react-fast-marquee";

const BankInfoMarquee = () => {
    return (
        <Marquee pauseOnHover={true} speed={70}>
            <div className={styles.banner}>

            <div className={styles['delivery-images']}>
                <img src={bank} alt="bank" />
                <img src={scotia} alt="scotia" />
                <img src={ncb} alt="ncb" />
            </div>
            <div>
                <p className={styles['phone-number']}>Welcome to our website! Please note that we currently accept payment via bank transfers ONLY. If you have any questions, you may contact us at 876-238-2419</p>
            </div>
            <div>
            </div>
        </div>
        </Marquee>
    );
};

export default BankInfoMarquee;