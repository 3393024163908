import React, { Fragment } from 'react';
import styles from './FeaturedCategoryCarousel.module.css'; // Ensure this path is correct
import FeaturedCategoryCard from './FeaturedCategoryCard';

const FeaturedProductCarousel = () => {
    const featuredCategory = [
        {
            id: 1,
            categoryName: 'Event Stationery',
            img: '../Other/wedding.png',
            url: '/wedding'
        },
        {
            id: 2,
            categoryName: 'Business Stationery',
            img: '../Other/corporate.png',
            url: '/corporate'
        },
        {
            id: 3,
            categoryName: 'Graphic Design',
            img: '../Other/training.png',
            url: '/other-services'
        }
    ];

    return (
        <Fragment>
            <div className={styles['product-carousel-container']}>
                {featuredCategory.map((event) => (
                    <div key={event.id} className={styles['product-card-container']}>
                        <FeaturedCategoryCard category={event} />
                    </div>
                ))}
            </div>
        </Fragment>
    );
};

export default FeaturedProductCarousel;

