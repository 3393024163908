import React from 'react';
import styles from './ReviewsCards.module.css';
import google from '../assets/images/google.png';
import whatsapp from '../assets/images/WhatsApp_icon.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStar} from '@fortawesome/free-solid-svg-icons';

const ReviewsCards = () => {
    const reviewsData = [
        {
            name: 'Rochell',
            date: '2-11-2024',
            reviewMessage: 'Very professional, fast turn around time and very patient. Top tier customer service..though we went the route of digital invite,best believe when I say pci_neikadesigns stayed on the phone with me just to make sure everything was perfect and that I was fully satisfied.All our guests are loving the invitation.Thank you again so very much for your service.',
            socialMedia: 'whatsapp',
        },
        {
            name: 'Roxanne',
            date: '30-10-2024',
            reviewMessage: 'I do luv my programs and cake boxes, I do appreciate your hard work. Thank you.',
            socialMedia: 'whatsapp',
        },
        {
            name: 'Jodiann',
            date: '31-07-2024',
            reviewMessage: `I'm in love with the work from the digital to physical ☺☺☺☺.`,
            socialMedia: 'whatsapp',
        },
        {
            name: 'T. Beckford',
            date: '20-6-2024',
            reviewStarCount: 5,
            reviewMessage: 'I received my package thank you. I love them.',
            socialMedia: 'whatsapp',
        }, 
        {
            name: 'Kemesha',
            date: '12-6-2024',
            reviewMessage: 'Excellent customer service.  The service time provided was prompt and I was pleased with my invitations. Hoping to get my souvenirs done also. Thank you for your service once more ',
            socialMedia: 'Whatsapp',
        },
        {
            name: 'Horseback Ocean Riding',
            date: '22-02-2024',
            reviewStarCount: 5,
            reviewMessage: '',
            socialMedia: 'Google',
        }, 
        { 
            name: 'Paula Virgo',
            date: '10-01-2024',
            reviewStarCount: 5,
            reviewMessage: 'Customer Service is top tier not to mention the quality of the products ♥',
            socialMedia: 'Google',
        },
        {
            name: 'S. Smellie',
            date: '11-11-2023',
            reviewStarCount: 5,
            reviewMessage: 'Did a last minute order and I got it within like 2 days after contacting them. If you’re running late or have a mishap or anything trust me look no further PC&I is the final stop. Thank you again',
            socialMedia: 'Google',
        }, 
        {
            name: 'Instituteja',
            date: '11-12-2022',
            reviewStarCount: 5,
            reviewMessage: 'Great customer service! Owner ensured I got my invitation on time even though it was a short notice.. Appreciate it :) Def would recommend',
            socialMedia: 'Google',
        },
        {
            name: 'L. Edwards',
            date: '12-2-2022',
            reviewStarCount: 5,
            reviewMessage: 'Design is top tier...great customer service definitely recommend...',
            socialMedia: 'Google',
        },
    
        {
            name: 'A. Student',
            date: '11-10-2022',
            reviewStarCount: 5,
            reviewMessage: 'AWESOME on time designs! Great quality...definitely will shop again!!',
            socialMedia: 'Google',
        },
    
    ];

    return (
        <div>
            <div className={styles.container}>
                <div className={styles['review-section']}>

                    {reviewsData.map((data) => {
                        return (
                            <div className={styles['review-item']}>
                                <div className={styles.top}>
                                    <div className={styles['client-image']}>
                                        {
                                            data.socialMedia === 'Google' ?
                                                <img src={google} alt="google logo"  loading="lazy"/>
                                                :
                                                <img src={whatsapp} alt="whatsapp logo"  loading="lazy"/>

                                        }
                                        <span className={styles.reviewer}>{data.name}</span>
                                        <ul>
                                            {Array.from({length: data.reviewStarCount}).map((_, index) => (
                                                <li key={index}>
                                                    <FontAwesomeIcon icon={faStar}/>
                                                </li>))}
                                        </ul>
                                    </div>

                                </div>
                                <article>
                                    <p className="review">
                                        {data.reviewMessage}
                                    </p>
                                    <p>{data.date}</p>
                                </article>
                            </div>
                        )

                    })}

                </div>

            </div>
        </div>


    );
};

export default ReviewsCards;
